import React from 'react';
import { createRoot } from 'react-dom/client';
import { HashRouter, Routes, Route } from 'react-router-dom';
import './index.css';
import 'semantic-ui-css/semantic.css';

import Layout from './layouts/Layout';
import WelcomePage from './pages/WelcomePage';
import TablePage from './pages/TablePage';
import ErrorPage from './pages/ErrorPage';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <HashRouter>
    <Routes>
      <Route path="/" element={<Layout><WelcomePage /></Layout>} />
      <Route path="/chat/:id" element={<Layout><TablePage /></Layout>} />
      <Route path="*" element={<Layout><ErrorPage /></Layout>} />
    </Routes>
  </HashRouter>
);