import { Header, Icon, Segment } from 'semantic-ui-react';

function ErrorPage() {
  return (
    <Segment placeholder style={{ width: '100vw', height: '100vh' }}>
      <Header icon>
        <Icon name='frown outline' />
        Страница не найдена
      </Header>
    </Segment>
  );
}

export default ErrorPage;
