import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Table, Popup, Message, Header, Icon, Segment, Label } from 'semantic-ui-react';
const LZString = require('lz-string');

const TEXT = {
  COLUMN_HINT: 'Получил реакции',
  ROW_HINT: 'Поставил реакции',
}

function exportName(text) {
  return text.replace(/[a-z]/gi, (code) =>
    String.fromCharCode(code.charCodeAt() + 13 - 26 * /[n-z]/i.test(code))
  );
}

function prepareUrl(id) {
  return `/api/reactions/${id}`;
}

function safeParseJSON(text, fallback) {
  try {
    return JSON.parse(text);
  } catch (err) {
    return fallback;
  }
}

function createStyleElement(content) {
  const element = document.createElement('style');
  element.type = 'text/css';
  element.innerText = content;
  document.head.appendChild(element);

  return element;
}

const hoverElement = createStyleElement('.row.column { background-color: transparent !important; box-shadow: none !important; }');

const Relations = ({ id, revision, relations, classes }) => {
  const names = Object.keys(relations);

  function onHover(rootX, rootY) {
    hoverElement.innerText = [
      `.row.column:not(.${rootX}) { background-color: transparent; }`,
      `.row.column:not(.${rootY}) { box-shadow: none; }`
    ].join(' ');
  }

  return (
    <Table striped compact celled definition>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell />
          {names.map((name) => {
            return (<Popup position='right center' content={TEXT.COLUMN_HINT} trigger={<Table.HeaderCell>{name}</Table.HeaderCell>} />)
          })}
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {names.map((name, i) => {
          return (
            <Table.Row>
              <Popup position='bottom left' content={TEXT.ROW_HINT} trigger={
                <Table.Cell collapsing>
                  {name}
                </Table.Cell>
              }/>

              {Object.values(relations[name]).map((element) => {
                return (<Table.Cell
                    className={`row ${element.rootX} ${element.classX} column ${element.rootY} ${element.classY}`}
                    onMouseEnter={() => onHover(element.rootX, element.rootY)}>
                    {element.value}
                </Table.Cell>
                )
              })}
            </Table.Row>
          )
        })}
      </Table.Body>
    </Table>
  )
}

function TablePage() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [state, setState] = useState({});

  useEffect(() => {
    setIsLoading(true);

    fetch(prepareUrl(id))
      .then((response) => response.text())
      .then((response) => {
        const state = safeParseJSON(LZString[exportName('qrpbzcerffSebzOnfr64')](response));
        if (!state) {
          return navigate('/404');
        }

        const classesText = Object.entries(state.classes).map(([classname, value]) => { return `${classname} { ${value}; }` }).join(' ');
        createStyleElement(classesText);

        setState(state);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
        navigate('/404');
      });
  }, [id, navigate, setState, setIsLoading]);

  if (isLoading) {
    return (
      <Segment placeholder style={{ width: '100vw', height: '100vh' }}>
        <Header icon>
          <Icon name='sync alternate' loading />
          Загружаем данные
        </Header>
      </Segment>
    )
  }

  return (
    <div className="Table">
      <Message size='massive' color='black'>
        <Container>
          <Header
            inverted
            as='h1'
            content='seerstone'
            subheader='полученные и отправленные реакции в телеграм чате'
          />
        </Container>
      </Message>

      <Container>
        <Message icon warning>
          <Icon name='help' />
          <Message.Content>
            По строке (красный цвет) — это сколько пользователь поставил реакций.<br/>
            По колонке (зелёный цвет) — это сколько пользователь получил реакций.
          </Message.Content>
        </Message>

        <Relations {...state} />

        <Segment attached>
          {state.emoticons.map((emoticon) => (
            <Label size={'mini'}>
              {emoticon.emoji}
              <Label.Detail>{emoticon.count}</Label.Detail>
            </Label>
          ))}
        </Segment>

        <Segment basic size={'tiny'} textAlign={'right'}>chat id = {state.id}, content revision = {state.revision}</Segment>
      </Container>
    </div>
  );
}

export default TablePage;
